import dayjs from "dayjs";
import { ICampaign } from "redcircle-types";
import {
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCanceled,
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateDraft,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignItemStateSent,
  CampaignStateCompleted,
} from "src/constants/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";

export const getCampaignItemField = (
  field: keyof ICampaignItem | "endAt" | "pixelRequired" | "forecast.endDate",
  { campaignItem, campaign }: { campaignItem: ICampaignItem; campaign?: ICampaign }
): any => {
  // these fields have been replaced by overridable fields
  switch (field) {
    case "startAt":
      return campaignItem.isV2
        ? campaignItem?.lifecycleSettings?.startAt?.value
        : campaignItem?.startAt;
    case "endAt":
      if (!campaignItem.isV2) return campaign?.endsAt;
      const campaignItemEndsAt = campaignItem?.lifecycleSettings?.endAt?.value;
      if (campaignItemEndsAt && campaignItemEndsAt < 0 && campaign) return campaign.endsAt;
      return campaignItemEndsAt;
    case "forecast.endDate":
      if (!campaignItem.isV2) return false;
      const forecastEndDate = campaignItem?.forecast?.endDate;
      if (forecastEndDate && forecastEndDate > 0) return forecastEndDate;
      return false;
    case "frequencyConfigs":
      return campaignItem.isV2 ? campaignItem?.frequencyConfigs?.value : campaign?.frequencyConfigs;
    case "hardEndDate":
      return campaignItem.isV2 ? campaignItem?.hardEndDate?.value : campaign?.hardEndDate;
    case "pacing":
      return campaignItem.isV2 ? campaignItem?.pacing?.value : campaign?.pacing;
    case "requiresEndorsement":
      return campaignItem.isV2
        ? campaignItem?.requiresEndorsement?.value
        : campaign?.requiresEndorsement;
    case "pixelRequired":
      return campaignItem.isV2
        ? campaignItem?.lifecycleSettings?.pixelRequired?.value
        : campaign?.pixelRequired;
    case "targetingOptions":
      return campaignItem.isV2 ? campaignItem?.targetingOptions?.value : campaign?.targetingOptions;
    case "recentEpisodesOnly":
      return campaignItem.isV2
        ? campaignItem?.recentEpisodesOnly?.value
        : campaign?.recentEpisodesOnly;
    default:
      return campaignItem[field];
  }
};

const OVERESTIMATION_CUTOFF_DAYS = 28;
const OVERESTIMATION_PERCENTAGE = 0.5;

export const isCampaignItemUnderdelivering = ({
  campaignItem,
  campaign,
}: {
  campaignItem: ICampaignItem;
  campaign?: ICampaign;
}) => {
  if (
    [
      CampaignItemStateDraft,
      CampaignItemStateCompleted,
      CampaignItemStateDeclined,
      CampaignItemStateExpired,
    ].includes(campaignItem.state)
  ) {
    return false;
  }

  const forecastData = campaignItem.forecast;
  const pacing = getCampaignItemField("pacing", { campaignItem });
  const hardEndDate = getCampaignItemField("hardEndDate", { campaignItem, campaign });
  let isUnderdelivering = false;

  if (pacing && hardEndDate && forecastData?.unspentBudget && forecastData.unspentBudget > 0) {
    isUnderdelivering = true;
  }

  if (pacing && !hardEndDate) {
    const estimatedEndDate =
      forecastData?.endDate && forecastData.endDate > 0 ? dayjs.unix(forecastData.endDate) : null;
    const endAt = getCampaignItemField("endAt", { campaignItem, campaign });
    const targetEndDate = endAt ? dayjs.unix(endAt) : null;
    const startAt = getCampaignItemField("startAt", { campaignItem });
    const targetStartDate = startAt ? dayjs.unix(startAt) : null;

    if (estimatedEndDate && targetEndDate && targetStartDate) {
      const overestimation = estimatedEndDate.diff(targetEndDate, "days");
      const campaignLength = targetEndDate.diff(targetStartDate, "days");

      if (
        overestimation > campaignLength * OVERESTIMATION_PERCENTAGE ||
        overestimation > OVERESTIMATION_CUTOFF_DAYS
      ) {
        isUnderdelivering = true;
      }
    }
  }

  return isUnderdelivering;
};

/**
 * Campaign Item lifeCycle Utility functions
 */

export const IsItemCanceled = (item: ICampaignItem) => {
  return item?.lifecycleSettings?.canceled;
};

export const isItemDeclined = (item: ICampaignItem) => {
  return item.lifecycleSettings.responseTask.completed && !item?.response?.accepted;
};

export const isItemExpired = (item: ICampaignItem) => {
  const { responseTask, assignAudioTask } = item.lifecycleSettings;
  const now = dayjs();

  const responseTaskDeadline = dayjs.unix(responseTask?.deadline?.value);
  const missedResponseDeadline = !responseTask.completed && now.isAfter(responseTaskDeadline);

  const assignAudioDeadline = dayjs.unix(assignAudioTask?.deadline?.value);
  const missedAudioDeadline = !assignAudioTask.completed && now.isAfter(assignAudioDeadline);

  return missedResponseDeadline || missedAudioDeadline;
};

const isItemMissingPixel = (item: ICampaignItem) => {
  if (!item.isV2) return false;

  const now = dayjs();
  const responseDeadline = dayjs.unix(item.lifecycleSettings.responseTask.deadline.value);
  const assignAudioDeadline = dayjs.unix(item.lifecycleSettings.assignAudioTask.deadline.value);

  const isSent = item.lifecycleSettings.sendTask.completed;
  const missedResponseDeadline =
    !item.lifecycleSettings.responseTask.completed && now.isAfter(responseDeadline);
  const missedAudioDeadline =
    !item.lifecycleSettings.assignAudioTask.completed && now.isAfter(assignAudioDeadline);

  if (
    !isSent ||
    isItemDeclined(item) ||
    IsItemCanceled(item) ||
    missedAudioDeadline ||
    missedResponseDeadline
  ) {
    return false;
  }

  if (!item.lifecycleSettings.pixelRequired.value) {
    return false;
  }

  if (item.lifecycleSettings.assignPixelTask.completed) {
    return false;
  }

  return true;
};

/**
 * Calculates campaign item state from lifecycle settings. Mimics BE logic 1:1
 */
export const calculateCampaignItemState = (item: ICampaignItem, campaign: ICampaign) => {
  if (!item.isV2) {
    return item.state;
  }

  if (IsItemCanceled(item)) return CampaignItemStateCanceled;

  if (!item.lifecycleSettings.sendTask.completed) {
    return CampaignItemStateDraft;
  }

  const now = dayjs();
  const responseDeadline = dayjs.unix(item.lifecycleSettings.responseTask.deadline.value);
  const assignAudioDeadline = dayjs.unix(item.lifecycleSettings.assignAudioTask.deadline.value);
  const startAt = dayjs.unix(item.lifecycleSettings?.startAt?.value);

  // Sent but not responded -> expired or sent
  if (!item.lifecycleSettings.responseTask.completed) {
    if (responseDeadline.isBefore(now)) {
      return CampaignItemStateExpired;
    } else {
      return CampaignItemStateSent;
    }
  }

  // Responded but not accepted
  if (!item?.response?.accepted) {
    return CampaignItemStateDeclined;
  }

  if (!item.lifecycleSettings.assignScriptTask.completed) {
    if (!item.lifecycleSettings.assignAudioTask.completed && assignAudioDeadline.isBefore(now)) {
      return CampaignItemStateExpired;
    } else {
      return CampaignItemStateNeedsScript;
    }
  }

  if (!item.lifecycleSettings.assignAudioTask.completed) {
    if (assignAudioDeadline.isBefore(now)) {
      return CampaignItemStateExpired;
    } else {
      return CampaignItemStateAwaitingAudio;
    }
  }

  if (campaign.state === CampaignStateCompleted) {
    return CampaignItemStateCompleted;
  }

  if (item.lifecycleSettings.swapAudioPending) {
    return CampaignItemStateAudioSwapRequested;
  }

  if (item.lifecycleSettings.paused) {
    return CampaignItemStatePaused;
  }

  if (isItemMissingPixel(item)) {
    if (startAt.isBefore(now)) {
      return CampaignItemStatePaused;
    }
  }

  return CampaignItemStateRunning;
};
