import { Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import {
  ICampaign,
  ICampaignItem,
  isCampaignItem,
  IShow,
  Promotion,
  PublicShow,
} from "redcircle-types";
import { COLORS } from "redcircle-ui";
import RCTag from "redcircle-ui/components/Tag/Tag";
import FormDropdown from "src/components/lib/form_dropdown";
import {
  SummaryPageInfoSecondTier,
  SummaryPageSectionWrapper,
} from "src/components/lib/summary-page-sections/summary-page-sections";
import { getAverageCPM, getImpressionsFromBudget } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { calculateCampaignItemCuts } from "src/lib/promotions";

export default function ShowCampaignPayment({
  campaign,
  campaignItem,
  show,
}: {
  campaign: ICampaign;
  campaignItem?: ICampaignItem;
  show: IShow | PublicShow;
}) {
  if (!campaign || !campaignItem) return null;

  const isRateRenegotiated = Boolean(campaignItem?.offerRates?.enabled);

  const originalCPM = getAverageCPM({ show, campaign, campaignItem, when: "original" });
  const currentCPM = getAverageCPM({ show, campaign, campaignItem, when: "final" });
  const impressions = Math.round(
    getImpressionsFromBudget({
      cpm: currentCPM,
      budget: campaignItem.totalBudget,
    })
  );

  const [itemCuts, setItemCuts] = useState<{
    totalInCents: number;
    creatorTotalInCents: number;
    redCircleTotalInCents: number;
    newRedCircleCutInPercent: number;
    originalRedCircleCutInPercent: number;
    isPromotionActive: boolean;
    activePromotions: Promotion[];
    promotionsMaxDeductInCents: number;
  }>({
    totalInCents: 0,
    creatorTotalInCents: 0,
    redCircleTotalInCents: 0,
    newRedCircleCutInPercent: 0,
    originalRedCircleCutInPercent: 0,
    isPromotionActive: false,
    activePromotions: [],
    promotionsMaxDeductInCents: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  const {
    creatorTotalInCents,
    newRedCircleCutInPercent,
    originalRedCircleCutInPercent,
    promotionsMaxDeductInCents,
    isPromotionActive: isPromotionApplicable,
  } = itemCuts;

  const promotionTooltip = `Up to ${formatMoney(promotionsMaxDeductInCents)} deducted`;

  const redCircleCommissionText = isPromotionApplicable ? (
    <div className="flex-row-container align-center">
      {`${newRedCircleCutInPercent}%`}{" "}
      <span className="m-lxxxs" style={{ textDecoration: "line-through" }}>{`${
        originalRedCircleCutInPercent
      }%`}</span>
    </div>
  ) : (
    `${originalRedCircleCutInPercent}%`
  );

  const isValidItem = isCampaignItem(campaignItem);

  useEffect(() => {
    if (isValidItem) {
      calculateCampaignItemCuts({ campaignItem })
        .then((cuts) => {
          setItemCuts(cuts);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isValidItem]);

  return (
    <SummaryPageSectionWrapper title={"Payment"}>
      <div>
        <Spin spinning={isLoading}>
          <FormDropdown
            label={
              <div className="width-100 flex-row-container justify-space-between">
                <div>Total Payment:</div>
                <div className="campaign-total-payment" style={{ fontSize: 15 }}>
                  {formatMoney(creatorTotalInCents)}
                  {isRateRenegotiated && (
                    <Tooltip title="This amount has been updated by the brand">
                      <AiFillDollarCircle
                        className="m-lxxxs"
                        style={{ fill: COLORS.BLUE_MEDIUM }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            }>
            <div style={{ marginRight: 34 }}>
              <SummaryPageInfoSecondTier
                title={"Avg. CPM Rate"}
                text={
                  <>
                    {formatMoney(currentCPM)}
                    {isRateRenegotiated && (
                      <span className="crossed-out-cpm">{formatMoney(originalCPM)}</span>
                    )}
                  </>
                }
              />
              <SummaryPageInfoSecondTier
                title={"Total Downloads Purchased"}
                text={impressions.toLocaleString()}
              />
              <SummaryPageInfoSecondTier
                title={
                  <div className="flex-row-container align-center">
                    RedCircle Commission{" "}
                    {isPromotionApplicable && (
                      <Tooltip title={promotionTooltip}>
                        <span>
                          <RCTag className="m-lxxs bold" color={COLORS.PRIMARY_COLOR}>
                            Promotion
                          </RCTag>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                }
                text={redCircleCommissionText}
              />
            </div>
          </FormDropdown>
        </Spin>
        <div style={{ fontSize: 13 }}>
          Your net earnings are based on the CPM, show size, and RedCircle's commission. The payment
          schedule will be based on the advertiser's invoicing requirements, which may vary. Payout
          occurs once the advertiser completes payment to RedCircle.
        </div>
      </div>
    </SummaryPageSectionWrapper>
  );
}
