import { ICampaignItem } from "redcircle-types";
import {
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateExpired,
  CampaignItemStateRunning,
} from "../consts/campaignItem";

/** isCampaignNotStarted determines whether campaign is in a waiting state */
export const isCampaignItemNotStarted = (campaignItem: ICampaignItem): boolean | null => {
  if (!campaignItem) return null;
  return ![
    CampaignItemStateRunning,
    CampaignItemStateCompleted,
    CampaignItemStateDeclined,
    CampaignItemStateExpired,
  ].includes(campaignItem.state);
};
