import { ConfigProvider, Select, Spin, Tag } from "antd";
import { SelectProps } from "antd/lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IBrand } from "redcircle-types";
import { getBrandsBulk, searchBrands } from "src/action_managers/brands";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";

interface IBrandsExcludeAutoComplete<T> {
  value?: T;
  onChange?: (value: T) => void;
}

const SEARCH_DEBOUNCE_WAIT_MILLISECONDS = 600;

export const BrandsExcludeAutoComplete = ({
  value = [],
  onChange,
}: IBrandsExcludeAutoComplete<string[]>) => {
  const dispatch = useDispatchTS();

  const [searchValue, setSearchValue] = useState("");

  const allBrandsByInstanceUUID = useSelectorTS((state) => {
    return { ...state.brands.brandsByInstanceUUID, ...state.brands.brandSearchByInstanceUUID };
  });

  const missingBrandInstanceUUIDs = value?.filter(
    (instanceUUID) => !allBrandsByInstanceUUID[instanceUUID]
  );

  // Brand options shown in dropdown post search obtained via search query
  const [brandOptions, setBrandOptions] = useState<Array<Partial<IBrand>>>([]);

  const handleChange = (values: string[]) => {
    onChange?.([...values]);
    setSearchValue("");
  };

  /**
   * Search function
   */
  const handleSearch = (value: string) => {
    setSearchValue(value);
    fetchSearchValue(value);
  };
  /**
   * Debounced search fetch function
   */
  const fetchSearchValue = useCallback(
    debounce((query: string) => {
      if (!isQueryValid(query)) return;

      dispatch(searchBrands(query)).then((res) => {
        if (res.status === 200) {
          const brands = res.json;
          setBrandOptions([...brands]);
        }
      });
    }, SEARCH_DEBOUNCE_WAIT_MILLISECONDS),
    []
  );

  /**
   * On Initialize, need to get brand info for currently excluded instance UUIDs
   */
  useEffect(() => {
    if (value.length === 0) return;

    if (missingBrandInstanceUUIDs.length === 0) {
      const brands = value.map((instanceUUID) => {
        return {
          ...allBrandsByInstanceUUID[instanceUUID],
        };
      });

      setBrandOptions(brands);
    } else {
      dispatch(getBrandsBulk(missingBrandInstanceUUIDs));
    }
  }, [missingBrandInstanceUUIDs?.length]);

  /**
   *  Loading tag render component, used for init loading state
   */
  const customTagRender: SelectProps["tagRender"] = (props) => {
    const { closable, label, value, disabled, onClose, isMaxTag } = props;

    const brandName = allBrandsByInstanceUUID?.[value]?.name;

    if (typeof brandName !== "string") {
      return (
        <Tag color="default">
          <div className="m-hxxs m-vxxxs">
            <Spin size="small" />
          </div>
        </Tag>
      );
    }

    return (
      <ConfigProvider
        theme={{
          components: {
            Tag: {
              defaultBg: "rgba(0, 0, 0, 0.06)",
            },
          },
        }}>
        <Tag
          closable={closable}
          style={{ fontSize: "16px", lineHeight: "30px", margin: "2px" }}
          onClose={onClose}>
          {brandName}
        </Tag>
      </ConfigProvider>
    );
  };

  return (
    <Select<string[], { label?: string; value?: string }>
      showSearch
      size="large"
      mode="multiple"
      className="width-100"
      placeholder="example: Nike"
      defaultActiveFirstOption={false}
      autoClearSearchValue={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      options={transFormBrandToOptions(brandOptions)}
      value={value}
      searchValue={searchValue}
      tagRender={customTagRender}
      onChange={handleChange}
    />
  );
};
/**
 * Simple validation for brand search query, can filter out simple things early on when user is typing.
 * i.e. empty strings, maybe certain characters
 */
const isQueryValid = (query?: string) => {
  if (typeof query !== "string") return false;
  if (query.length === 0) return false;

  return true;
};

/**
 * Helper func to transform brand selections to readable options in the search dropdown
 */
const transFormBrandToOptions = (brandOptions: Partial<IBrand>[]) => {
  return brandOptions?.map((brand) => {
    return { label: brand.name, value: brand.instanceUUID };
  });
};
