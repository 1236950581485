import { CampaignItemState } from "redcircle-types";

export const CampaignItemStateNeedsScript: CampaignItemState = "awaiting-script";
export const CampaignItemStateAwaitingAudio: CampaignItemState = "awaiting-audio-upload";
export const CampaignItemStateSent: CampaignItemState = "sent";
export const CampaignItemStateDraft: CampaignItemState = "draft";
export const CampaignItemStateNone: CampaignItemState = "none";
export const CampaignItemStateRunning: CampaignItemState = "running";
export const CampaignItemStateAccepted: CampaignItemState = "accepted";
export const CampaignItemStateCompleted: CampaignItemState = "completed";
export const CampaignItemStatePaused: CampaignItemState = "paused";
export const CampaignItemStateExpired: CampaignItemState = "expired";
export const CampaignItemStateDeclined: CampaignItemState = "declined";
export const CampaignItemStateAudioSwapRequested: CampaignItemState = "audio-swap-requested";
export const CampaignItemStateCanceled: CampaignItemState = "canceled";
