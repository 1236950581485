import { useContext } from "react";
import { localDate } from "src/lib/date";
import { ICampaignItem } from "src/reducers/campaign_items";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";
import { AiFillWarning } from "react-icons/ai";
import { COLORS } from "redcircle-ui";
import { IconContext } from "react-icons";

export default function SchedulerTimelineCell({
  campaignItem,
}: {
  campaignItem: ICampaignItem & { overrides: any };
}) {
  const { timeline, minEndDatesState, maxImpressionsState, budgets } =
    useContext(CampaignSchedulerContext);

  const isBudgetsEmpty = Object.values(budgets).length === 0;
  const { minEndDates, error, loadingShowUUIDs } = minEndDatesState;
  const { maxImpressions } = maxImpressionsState;

  const isTimelineLoading = loadingShowUUIDs.has(campaignItem.showUUID);

  let startDate = timeline?.[0]?.unix() ?? 0;
  if (campaignItem.lifecycleSettings.startAt?.overridden) {
    startDate = campaignItem.lifecycleSettings.startAt.value;
  }

  const { estimatedEndTime } = minEndDates?.[campaignItem?.showUUID] ?? {};
  const { impressions } = maxImpressions?.[campaignItem.showUUID] ?? {};
  const isErrored = campaignItem.pacing.value
    ? maxImpressions?.[campaignItem.showUUID]?.streamulatorErrored ||
      typeof impressions !== "number" ||
      (typeof impressions === "number" && impressions <= 0)
    : minEndDates?.[campaignItem.showUUID]?.streamulatorErrored ||
      (typeof estimatedEndTime === "number" && estimatedEndTime < 0);
  const forecastedEndDate = minEndDates?.[campaignItem.showUUID]?.estimatedEndTime;
  let endDate = forecastedEndDate ?? 0;

  const isPaced = campaignItem.pacing.value;
  if (isPaced && campaignItem.lifecycleSettings.endAt) {
    endDate = campaignItem.lifecycleSettings.endAt.value;
  }

  /**
   * On v2 items only, helps to visually let user know the timeline shown is either paced or estimated
   */
  const itemPacingState = campaignItem?.isV2 ? (
    <p className="fs-11 lh-11 pa-0 m-a0">{isPaced ? "Evenly Paced" : "Estimated Forecast"}</p>
  ) : (
    ""
  );

  const showNA = campaignItem.pacing.value ? false : isErrored;
  const showWarning = !isBudgetsEmpty && isErrored;

  return (
    <div className="flex-column-container justify-start">
      <div className="flex-row-container align-center m-bxxxs">
        {itemPacingState}{" "}
        {showWarning && (
          <IconContext.Provider value={{ size: "16px" }}>
            <AiFillWarning className="m-lxxs" color={COLORS.COLOR_WARNING} />
          </IconContext.Provider>
        )}
      </div>

      <div className="no-wrap">
        {localDate(startDate)}
        {isTimelineLoading ? "..." : ` - ${showNA ? "N/A" : localDate(endDate)}`}
      </div>
    </div>
  );
}
