import { Tooltip } from "antd";
import { AiFillWarning } from "react-icons/ai";
import {
  CampaignItemStateDeclined,
  CampaignItemStateExpired,
  formatMoney,
  isCampaignItemNotStarted,
} from "redcircle-lib";
import { ICampaign } from "redcircle-types";
import { COLORS } from "redcircle-ui";
import ProgressCircle from "src/components/lib/progress_circle";
import { getCampaignItemField, isCampaignItemUnderdelivering } from "src/lib/campaign_item";
import { localDateAndTime } from "src/lib/date";
import { humanizeNumber } from "src/lib/numbers";
import { ICampaignItem } from "src/reducers/campaign_items";

interface IProps {
  campaignItem: ICampaignItem;
  campaign?: ICampaign;
  campaignStats?: any;
  isPodcaster?: boolean;
}

const UnderdeliveryWarning = ({
  campaignItem,
  campaign,
  className,
  isPodcaster,
  progress,
}: {
  campaignItem: ICampaignItem;
  campaign?: ICampaign;
  className?: string;
  isPodcaster?: boolean;
  progress?: number;
}) => {
  const forecast = campaignItem.forecast;
  const estimatedEndDate = forecast?.endDate;
  const unspentBudget = forecast?.unspentBudget ? forecast.unspentBudget / 1000 : 0;
  const pacing = getCampaignItemField("pacing", { campaignItem });
  const hardEndDate = getCampaignItemField("hardEndDate", { campaignItem });
  const isUnderdelivering = isCampaignItemUnderdelivering({ campaignItem, campaign });
  if (!campaignItem.isV2 || !isUnderdelivering || !pacing) return null;

  let tooltipMessage;
  const prefix =
    isCampaignItemNotStarted(campaignItem) || progress === 0
      ? "Projected to underdeliver."
      : "Underdelivering.";
  if (hardEndDate && unspentBudget) {
    const expectedSpendingBudget = campaignItem.totalBudget - unspentBudget;
    const expectedDeliveryPercentage = (expectedSpendingBudget / campaignItem.totalBudget) * 100;

    const cut = campaignItem?.advertisingCutBasisPoints / 10000;
    const podcasterAmount = expectedSpendingBudget - expectedSpendingBudget * cut;
    const amount = isPodcaster ? podcasterAmount : expectedSpendingBudget;
    tooltipMessage = (
      <>
        <p className="m-b0">{prefix}</p>
        <p className="m-b0">
          Expected delivery: {expectedDeliveryPercentage.toFixed(2)}% ({formatMoney(amount / 100)})
        </p>
      </>
    );
  }
  if (!hardEndDate && estimatedEndDate) {
    tooltipMessage = (
      <>
        <p className="m-b0">{prefix}</p>
        <p className="m-b0">Expected end date: {localDateAndTime(estimatedEndDate)}</p>
      </>
    );
  }

  return (
    <Tooltip title={tooltipMessage}>
      <AiFillWarning
        style={{ height: 24, width: 24 }}
        className={className}
        color={COLORS.COLOR_WARNING}
      />
    </Tooltip>
  );
};

export default function CampaignProgress({
  campaignItem,
  campaign,
  campaignStats,
  isPodcaster,
}: IProps) {
  if (!showCampaignProgress(campaignItem)) return null;
  const { text, percent } = getCampaignProgress(campaignItem, campaignStats);

  return (
    <div className="flex-row-container align-center">
      <ProgressCircle percent={Math.floor(percent)} text={text} />
      <UnderdeliveryWarning
        campaignItem={campaignItem}
        campaign={campaign}
        className="m-lxxxs"
        isPodcaster={isPodcaster}
        progress={percent}
      />
    </div>
  );
}

export const getCampaignProgress = (campaignItem: ICampaignItem, campaignStats?: any) => {
  const { text, percent } = getContinuousStats(campaignItem, campaignStats);
  return { text, percent };
};

const getContinuousStats = (campaignItem: any, campaignStats?: any) => {
  const currentBudget = campaignItem.budget?.current ?? 0;
  const totalBudget = campaignItem.budget?.total ?? 1;

  // campaignStats are only used on the advertiser side for now
  // currently not passing on podcaster side because we don't want to give podcaster access
  // to performance of other campaign items on a campaign
  const downloadsByShowUUID = campaignStats?.downloadsByShowUUID;
  const downloads = downloadsByShowUUID?.[campaignItem?.show?.uuid];

  let percent = 0;
  if (campaignItem.budget) {
    percent = Math.min((currentBudget / totalBudget) * 100, 100);
  }

  const downloadsText = downloads ? ` | ${humanizeNumber(downloads)} Downloads` : "";

  return {
    text: `${percent.toFixed(2)}% Complete${downloadsText}`,
    percent,
  };
};

export const calculateProgressPercentage = (campaignItem: any) => {
  let percent = 0;
  if (!showCampaignProgress(campaignItem)) return percent;

  const currentBudget = campaignItem.budget?.current ?? 0;
  const totalBudget = campaignItem.budget?.total ?? 1;
  if (campaignItem.budget) {
    percent = Math.min((currentBudget / totalBudget) * 100, 100);
  }

  return percent;
};

export const showCampaignProgress = (campaignItem: ICampaignItem) => {
  // don't show progress on expired and declined campaigns
  return ![CampaignItemStateDeclined, CampaignItemStateExpired].includes(campaignItem.state);
};
