import { IBrand } from "redcircle-types";
import { defaultActionManagerRunner } from "./default_action_manager";

export const BRAND_GET = "BRAND_GET";
export const BRAND_GET_BULK = "BRAND_GET_BULK";
export const BRAND_CREATE = "BRAND_CREATE";
export const BRAND_SEARCH = "BRAND_SEARCH";
export const BRAND_UPDATE = "BRAND_UPDATE";

export const getBrand = (instanceUUID: string) => {
  return defaultActionManagerRunner<IBrand>({
    route: `brands/${instanceUUID}`,
    method: "get",
    actionName: BRAND_GET,
    auth: true,
  });
};

export const getBrandsBulk = (instanceUUIDs: string[]) => {
  return defaultActionManagerRunner<IBrand[]>({
    route: `brands/bulk`,
    method: "POST",
    actionName: BRAND_GET_BULK,
    body: {
      uuids: instanceUUIDs,
    },
    auth: true,
  });
};

export const createBrand = (brand: Partial<IBrand>) => {
  return defaultActionManagerRunner<IBrand>({
    route: `brands`,
    method: "post",
    actionName: BRAND_CREATE,
    body: brand,
    auth: true,
  });
};

export const searchBrands = (query: string) => {
  return defaultActionManagerRunner<Partial<IBrand>[]>({
    route: `brands?q=${query}`,
    method: "get",
    actionName: BRAND_SEARCH,
    auth: true,
  });
};

export const updateBrand = (brand: Partial<IBrand>) => {
  return defaultActionManagerRunner<IBrand>({
    route: `brands/${brand.instanceUUID}`,
    method: "put",
    actionName: BRAND_UPDATE,
    body: brand,
    auth: true,
  });
};
