import { captureException } from "@sentry/react";
import { Col, Row, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import React, { useEffect, useMemo } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import RCButton from "src/components/lib/button/button";
import { useCredentialLoginFlow } from "src/hooks/credentials";
import { User } from "redcircle-types";
import { showError } from "../../../actions/app";
import { CredentialUpdateActionManager } from "../../../action_managers/account";
import { loginWithCredInviteToken } from "../../../api/credentials";
import { useDispatchTS, useSelectorTS } from "../../../hooks/redux-ts";
import UserStore from "../../../lib/user_store";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import LoggedOutFrame from "../../lib/logged_out_frame";
import { useHideSidebar } from "../account/tier_utils";
import "./teamMember_sign_up.scss";

const formName = "TeamMemberSignUp";
const standardErrorMessage = "An error occurred, Could not sign in user";

interface RouteParams {
  encodedEmail: string;
  inviteToken: string;
}

interface ITeamMemberFormProps {
  firstName: string;
  lastName: string;
  password: string;
}

type formError = Partial<Record<keyof ITeamMemberFormProps, string>>;

type ITeamMemberSignUp = RouteComponentProps<RouteParams>;

const TeamMemberSignUp = (
  props: ITeamMemberSignUp & InjectedFormProps<ITeamMemberFormProps, ITeamMemberSignUp>
) => {
  const { match, submitting, handleSubmit, change, history } = props;
  const dispatch = useDispatchTS();

  const [credState, credDispatch] = useCredentialLoginFlow();

  const formValues = useSelectorTS((state) =>
    getFormValues(formName)(state)
  ) as ITeamMemberFormProps;

  const formSyncErrors = useSelectorTS((state) => getFormSyncErrors(formName)(state)) as formError;

  // Logic
  const userAlreadyExists = useMemo(() => !isEmpty(UserStore.get()), []);
  const credentialInviteToken = match.params?.inviteToken;
  const decodedEmail = atob(match.params?.encodedEmail);
  const disableSubmit =
    isString(formSyncErrors.firstName) ||
    isString(formSyncErrors.lastName) ||
    isString(formSyncErrors.password) ||
    !isString(formValues?.password) ||
    submitting;

  // Handlers
  const completeTeamSeatSignUp = async (formValues: ITeamMemberFormProps) => {
    const { firstName, lastName, password } = formValues;
    try {
      // Claim Team seat by updating new user
      const resp = await dispatch(
        new CredentialUpdateActionManager({
          userForm: { firstName, lastName, password },
          existingUser: credState?.user,
          customSuccess: "Successfully Signed Up",
        }).run()
      );

      if (resp?.status === 200) {
        // On Success redirect to main page
        if (credState.user?.roles?.creator) {
          history.push("/shows");
        } else if (credState.user?.roles?.advertiser) {
          history.push("/campaigns");
        } else {
          history.push("/");
        }
      } else {
        throw resp;
      }
    } catch (resp: any) {
      captureException(resp, {
        extra: {
          validationErrors: resp?.json?.validationErrors,
          message: resp.json?.message,
        },
      });

      const errorMessage =
        resp?.json?.validationErrors?.[0]?.errorMessage ??
        resp?.json?.message ??
        standardErrorMessage;

      dispatch(showError(errorMessage, 4000));
    }
  };

  const loginWithCredFlow = async () => {
    try {
      credDispatch({ type: "start" });

      const resp = await loginWithCredInviteToken(decodedEmail, credentialInviteToken);

      if (resp?.status !== 200) {
        const val = await resp.json();
        throw val;
      }

      const user: User = await resp.json();

      if (user) {
        credDispatch({ type: "success", payload: user });
        const { firstName, lastName } = user;

        isString(firstName) && change("firstName", firstName);
        isString(lastName) && change("lastName", lastName);
      }
    } catch (err) {
      credDispatch({ type: "error" });
    }
  };

  const handleAcceptInvite = () => {
    credDispatch({ type: "pre-start" });
  };

  // On First Render
  useEffect(() => {
    // Update Email form field
    change("email", decodedEmail);
  }, []);

  // When updating user object directly, sidebar opens up due to parent logic ,
  // need to close this
  useHideSidebar();

  /**
   * Fetching new credential information
   */
  useEffect(() => {
    if (!userAlreadyExists && !!credState?.userInitAccept) {
      loginWithCredFlow();
    }
  }, [userAlreadyExists, credState?.userInitAccept]);

  /**
   * Screen render
   */

  // If User already exists redirect them back to home page
  if (userAlreadyExists) {
    return <Redirect to="/shows" />;
  }

  if (!userAlreadyExists && !credState.userInitAccept) {
    return (
      <LoggedOutFrame className="withShadow" hideSignIn={true} hideSignUp={true} noBar={true}>
        <div className="height-100 width-100 flex-row-container justify-center align-center">
          <div
            className="flex-column-container justify-center align-center"
            style={{ maxWidth: "500px" }}>
            <h2 className="m-bs">Let’s get you added to your team! </h2>
            <RCButton type="primary" size="large" onClick={handleAcceptInvite}>
              Accept Invite
            </RCButton>
          </div>
        </div>
      </LoggedOutFrame>
    );
  }

  if (!userAlreadyExists && credState.success && !!credState?.user) {
    return (
      <LoggedOutFrame
        className="withShadow"
        title={"Team Member Sign Up"}
        subtitle={
          "You’ve been invited to join a podcast team! Create a password below to complete your sign up."
        }>
        <Row>
          <Col xs={24} md={{ span: 12, offset: 6 }}>
            <form onSubmit={handleSubmit(completeTeamSeatSignUp)} className="teamMemberSignUp">
              <Field
                name="firstName"
                label="First Name"
                type="text"
                placeholder="Enter user’s first name"
                component={FormElement}
                validateAfterTouch={true}
                required
              />
              <Field
                name="lastName"
                label="Last Name"
                type="text"
                placeholder="Enter user’s last name"
                component={FormElement}
                validateAfterTouch={true}
                required
              />
              <Field
                name="email"
                label="Email Address"
                type="email"
                placeholder="Enter user's email"
                disabled={true}
                component={FormElement}
                validateAfterTouch={true}
                required
              />
              <Field
                name="password"
                label="Password"
                type="password"
                component={FormElement}
                validateAfterTouch={true}
                required
              />
              <LoadingButton
                type="submit"
                className="p-hl"
                isLoading={Boolean(submitting)}
                disabled={disableSubmit}>
                Complete Sign Up
              </LoadingButton>
            </form>
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }

  // Could not fetch new User, there was an error with token
  if (!userAlreadyExists && credState.error) {
    return (
      <div
        className="height-100 width-100 flex-row-container justify-center align-center"
        style={{ height: "100vh" }}>
        <div
          className="flex-column-container justify-center align-center"
          style={{ maxWidth: "500px" }}>
          <h2 className="m-bxs">Invitation Expired</h2>
          <p className="text-center">
            Your invite has expired or has already been used. Check your email for a newer invite or
            ask your organization's administrator to re-send the invite.
          </p>
        </div>
      </div>
    );
  }

  // Is Loading new user from Cred Invite Token or loading the page

  return (
    <div
      className="height-100 width-100 flex-row-container justify-center align-center"
      style={{ height: "100vh" }}>
      <Spin />
    </div>
  );
};

// Helper Form Functions
const syncValidation = (values: ITeamMemberFormProps) => {
  const errors: Partial<Record<keyof ITeamMemberFormProps, string>> = {};

  let prop: keyof ITeamMemberFormProps;
  for (prop in values) {
    switch (prop) {
      case "firstName":
        if (values?.[prop]?.length === 0) {
          errors[prop] = `First name can not be empty`;
        }
        break;

      case "lastName":
        if (values?.[prop]?.length === 0) {
          errors[prop] = `Last name can not be empty`;
        }

        break;

      case "password":
        if (values?.[prop]?.length < 8) {
          errors[prop] = `Password must be at least 8 character long`;
        }
        if (values?.[prop]?.length === 0) {
          errors[prop] = `Password can not be empty`;
        }
        break;

      default:
        break;
    }
  }

  return errors;
};

// WRAPPED MODAL
const TeamMemberSignUpWrapper = reduxForm<ITeamMemberFormProps, ITeamMemberSignUp>({
  form: formName,
  initialValues: {
    firstName: "",
    lastName: "",
    password: "",
  },
  keepDirtyOnReinitialize: true,
  validate: syncValidation,
})(TeamMemberSignUp);

export default TeamMemberSignUpWrapper;
