import getAPIPrefix from "../lib/api_prefix";
import { ForecastEndDateRequest, ForecastImpressionsRequest, User } from "redcircle-types";
import { store } from "src";
import { showWarning } from "src/actions/app";

type POSITIONS = "PREROLL" | "MIDROLL" | "POSTROLL";

export const getCampaignForecastImpressions = (
  requestInfo: ForecastImpressionsRequest,
  user: User
) => {
  return fetch(`${getAPIPrefix()}campaign-forecast/impressions`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestInfo),
  });
};

export const getCampaignForecastEndDates = (requestInfo: ForecastEndDateRequest, user: User) => {
  return fetch(`${getAPIPrefix()}campaign-forecast/timeline`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestInfo),
  });
};

export const getCampaignZippedMediaFiles = ({
  campaignUUID,
  user,
}: {
  campaignUUID: string;
  user: User;
}) => {
  return fetch(`${getAPIPrefix()}campaigns/${campaignUUID}/media-files`, {
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/zip",
    },
  });
};

/**
 * Creator only endpoints, grabs total creator amount of v2 campaign items
 * @returns {number} Money in cents
 */

export const getCampaignItemTotalCreatorAmount = async (campaignItemUUID: string) => {
  try {
    const { user } = store.getState()?.user;

    const resp = await fetch(`${getAPIPrefix()}campaign-items/${campaignItemUUID}/creator-amount`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.authToken,
        "Content-Type": "application/json",
      },
    });

    if (resp.status === 200) {
      return (await resp.json()) as { totalCreatorAmount: number };
    } else {
      store.dispatch(
        showWarning(
          "Could not calculate campaign item total creator amount, Please contact RedCircle support for more information."
        )
      );
      return { totalCreatorAmount: 0 };
    }
  } catch (err) {
    console.error("Could not get campaign Item total creator Amount", err);
    return { totalCreatorAmount: 0 };
  }
};
