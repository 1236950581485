export const generateRSSFeedUrl = (showUUID: string) => {
  const env = process.env.NODE_ENV as string;
  if (env === "development") {
    return `http://localhost:8000/feed/${showUUID}`;
  }
  if (env === "staging") {
    return `https://staging-feeds.redcircle.com/${showUUID}`;
  }
  return `https://feeds.redcircle.com/${showUUID}`;
};
