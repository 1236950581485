import { Form, InputNumber, Popover, Tooltip } from "antd";
import numeral from "numeral";
import { ForwardedRef, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { AiFillExclamationCircle, AiFillWarning } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { Button, COLORS, Modal } from "redcircle-ui";
import { getAverageCPM, getBudget } from "src/lib/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";
import { getCampaignItemField } from "src/lib/campaign_item";
import { IconContext } from "react-icons";

export default function SchedulerBudgetCell({
  campaignItem,
  show,
}: {
  campaignItem: ICampaignItem;
  show: IShow;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // focus on input
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [showPopover]);

  const {
    campaign,
    excludedCampaignItems,
    budgets,
    setBudgets,
    maxImpressionsState,
    minEndDatesState,
    setItemBudgetUpdated,
  } = useContext(CampaignSchedulerContext);
  const isExcluded =
    campaignItem && excludedCampaignItems.map((item) => item.uuid).includes(campaignItem.uuid);

  const cpm = getAverageCPM({ show, campaign, campaignItem });

  const budget =
    campaignItem.uuid && typeof budgets[campaignItem.uuid] === "number"
      ? budgets[campaignItem.uuid]
      : 0;

  const maxImpressionsForShow = maxImpressionsState.maxImpressions?.[campaignItem.showUUID];
  const max =
    maxImpressionsForShow?.impressions > 0
      ? getBudget({ impressions: maxImpressionsForShow.impressions, cpm })
      : 0;

  const showMaxLimit =
    getCampaignItemField("pacing", { campaign, campaignItem }) &&
    typeof max === "number" &&
    max >= 0 &&
    typeof budget === "number" &&
    budget >= 0;

  const isBudgetsEmpty = Object.values(budgets).length === 0; // Check if the budgets have been initialized, implying streamulator has not run yet

  const isTooHigh = showMaxLimit && budget > max;
  const isZero = budget === 0 && max === 0;
  const showWarning = (isTooHigh || isZero) && !isBudgetsEmpty;

  const handleSubmitBudget = (value: number) => {
    setBudgets({ ...budgets, [campaignItem.uuid]: value });
    setItemBudgetUpdated((prev) => ({ ...prev, [campaignItem.uuid]: true }));
  };

  return (
    <Popover
      open={showPopover}
      content={
        <PopoverContent
          onClose={() => setShowPopover(false)}
          budget={budget}
          onSubmit={handleSubmitBudget}
          ref={inputRef}
          max={showMaxLimit ? max : undefined}
        />
      }
      trigger="click"
      onOpenChange={(open) => setShowPopover(open)}
      overlayInnerStyle={{ padding: 0 }}>
      <Button type="secondary" size="small" disabled={isExcluded} style={{ minWidth: "90px" }}>
        {numeral(budget / 100).format("$0,0.00")}
        {showWarning && (
          <IconContext.Provider value={{ size: "16px" }}>
            <AiFillWarning className="m-lxxxs" color={COLORS.COLOR_WARNING} />
          </IconContext.Provider>
        )}
      </Button>
    </Popover>
  );
}

const PopoverContent = forwardRef(function PopoverContent(
  {
    onClose,
    onSubmit,
    budget,
    max,
  }: {
    onClose: () => void;
    onSubmit: (value: number) => void;
    budget: number;
    max?: number;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = useState<number | null>(null);
  const isTooHigh =
    typeof max === "number" && max >= 0 && typeof value === "number" && value >= 0
      ? value > max / 100
      : false;
  const isZero = max === 0 && value === 0;

  const showWarning = isTooHigh || isZero;

  let tooltipMessage = "";

  if (isTooHigh) {
    tooltipMessage =
      "This budget exceeds the estimated maximum budget for your timeline. It is unlikely this budget will complete for dates you've selected.";
  } else if (isZero) {
    tooltipMessage = "Unable to forecast. Podcast may not have inventory for this configuration.";
  }

  useEffect(() => {
    if (typeof budget === "number") {
      setValue(budget / 100);
    }
  }, [budget]);

  const handleSubmit = () => {
    if (value === null) return;
    onSubmit(Math.round(value * 100));
    onClose();
  };

  return (
    <Form>
      <div className="p-axs flex-column-container">
        <span>
          <h5>Budget</h5>
          {typeof max === "number" && <span> Estimated Maximum: {formatMoney(max / 100)}</span>}
        </span>
        <div className="flex-row-container align-center m-txxxs">
          <InputNumber
            ref={ref}
            value={value}
            onChange={(value) => setValue(value)}
            prefix="$"
            size="large"
            precision={2}
            min={0.0}
            style={{ width: "100%" }}
            status={showWarning ? "warning" : undefined}
          />
          {showWarning && (
            <Tooltip title={tooltipMessage}>
              <IconContext.Provider value={{ size: "16px" }}>
                <AiFillWarning className="m-lxxxs" color={COLORS.COLOR_WARNING} />
              </IconContext.Provider>
            </Tooltip>
          )}
        </div>
      </div>
      <Modal.Footer className="p-axs">
        <Button type="link" className="p-a0" size="small" onClick={onClose}>
          Cancel
        </Button>
        <div className="flex-row-container align-center">
          <Tooltip title={tooltipMessage} placement="bottom">
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              className="m-lxxs"
              disabled={value === null}
              onClick={handleSubmit}>
              Save
            </Button>
          </Tooltip>
        </div>
      </Modal.Footer>
    </Form>
  );
});
